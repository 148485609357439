export const frequencyToSingularKeyMap = {
  DAY: 'DAY_QA',
  WEEK: 'PRODUCT_PAGE_PLAN_WEEK',
  MONTH: 'PRODUCT_PAGE_PLAN_MONTH',
  YEAR: 'PRODUCT_PAGE_PLAN_YEAR',
};

export const frequencyTo2KeyMap = {
  DAY: 'DAY_QA',
  WEEK: 'PRODUCT_PAGE_PLAN_2WEEKS',
  MONTH: 'PRODUCT_PAGE_PLAN_2MONTHS',
  YEAR: 'PRODUCT_PAGE_PLAN_2YEARS',
};

export const frequencyToPluralKeyMap = {
  DAY: 'DAY_QA',
  WEEK: 'PRODUCT_PAGE_PLAN_WEEKS',
  MONTH: 'PRODUCT_PAGE_PLAN_MONTHS',
  YEAR: 'PRODUCT_PAGE_PLAN_YEARS',
};
